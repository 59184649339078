import React from "react";
import CountdownTimer from "./CountdownTimer";

export default function GlobalCoundown() {
  const dateTimeAfterThreeDays = new Date(Date.UTC(2024, 2, 5, 19));
  return (
    <div className="max-w-7xl mx-auto px-0 sm:px-16 lg:px-20 sm:py-1">
      <div className="flex items-center justify-center bg-[#f9e9f3] px-2 gap-2 sm:rounded-md text-lg py-2">
        <CountdownTimer targetDate={dateTimeAfterThreeDays} />
      </div>
    </div>
  );
}

import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./hooks/useCountdown";

const ExpiredNotice = () => {
  return (
    <div>
      <span className="text-pink z-20 text-xl">
        Заказы на сайте не принимаются, звоните{" "}
        <a href="/contacts" className="text-accent font-semibold">
          по телефону
        </a>
      </span>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="font-[Taurus] bg-white rounded-md p-2 min-w-[13rem]">
      <div className="flex items-center gap-1 text-accent w-full  -mb-1">
        <DateTimeDisplay value={days} type={"д"} isDanger={days <= 2} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={"ч"} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={"мин"} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={"сек"} isDanger={false} />
      </div>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <>
        <a className="absolute inset-0" href="/deliveryinfo"></a>
        <span className="text-pink text-right leading-none font-semibold">
          Заказы на сайте принимаются еще:{" "}
        </span>
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      </>
    );
  }
};

export default CountdownTimer;

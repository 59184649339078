import React, { useState } from "react";
import { useTransition } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import InfoPage from "./Components/InfoPage";
import UndefinedPage from "./Components/UndefinedPage";
import Checkout from "./Pages/Checkout/Checkout";
import Confirmed from "./Pages/Confirmed/Confirmed";
import Contacts from "./Pages/Contacts/Contacts";
import Home from "./Pages/Home/Home";
import Payment from "./Pages/Payment/Payment";
import Product from "./Pages/Product/Product";
import Products from "./Pages/Products/Products";
import Services from "./Pages/Services/Services";
import Slide from "./Pages/Slide/Slide";
import mainImg from "./img/artiom-vallat-ZYSmVtcZGwg-unsplash.jpg";
import { XIcon } from "@heroicons/react/outline";
import { SocLinks } from "./Components/Footer";

const App = () => {
  const [popup, setPopup] = useState(false);
  let visited = localStorage["visited_1"];
  if (!visited) {
    //this is the first time
    localStorage["visited_1"] = true;
    setPopup(true);
  }
  return (
    <>
       {popup ? (
         <SpecialInfo setPopup={setPopup} />
       ) : (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="product/:productId" element={<Product />} />
      <Route path="slide/:slideId" element={<Slide />} />
      <Route path="products/:filter" element={<Products />} />
      <Route path="checkout" element={<Checkout />} />
      <Route path="services" element={<Services />} />
      <Route path="payment" element={<Payment />} />
      <Route path="contacts" element={<Contacts />} />
      <Route path="confirmed" element={<Confirmed />} />
      <Route path="*" element={<UndefinedPage />} />
      <Route path="deliveryinfo" element={<InfoPage />} />
    </Routes>
       ) 
       } 
    </>
  );
};

export default App;

const SpecialInfo = ({ setPopup }) => {
  return (
    <div className="mainWrapper bodywrapper text-lg">
      <div
        className="rounded-lg w-full mb-10 px-10 py-44 sm:py-64 relative"
        style={{
          backgroundImage: `url(${mainImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        <XIcon
          className="absolute -top-16 right-0 h-10 text-gray-400 hover:cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            setPopup(false);
          }}
        />
        <h2 className="text-3xl sm:text-4xl font-bold uppercase text-pink/70 max-w-sm md:max-w-sm ml-auto pl-14 sm:pl-10 text-right">
          Условия доставки на{" "}
          <span className="font-[Taurus] text-accent whitespace-nowrap">
            8 марта
          </span>
        </h2>
      </div>
      <div className="max-w-xl flex flex-col gap-5 mx-auto text-center">
        <p className="sm:text-2xl text-lg">
          <b>Заказы</b> на 6, 7, 8 числа принимаются до <b>5 марта</b>!
        </p>
        <p className="sm:text-2xl text-lg">
          Вы можете выбрать{" "}
          <a
            href="/products/all"
            className="text-accent font-bold hover:cursor-pointer hover:underline"
          >
            готовый букет
          </a>{" "}
          на сайте или заказать индивидуальный букет,{" "}
          <a
            href="/contacts"
            className="text-pink font-bold hover:cursor-pointer hover:underline"
          >
            написав нам
          </a>{" "}
          в соц. сетях или мессенджерах
        </p>
        <p className="sm:text-2xl text-lg">
          <b>Доставка</b> на 7 и 8 марта будет осуществляться в 3 интервала:
          <ol className="mt-1 sm:text-xl">
            <li>09:00 - 13:00</li>
            <li>13:00 - 19:00</li>
            <li>19:00 - 21:00</li>
          </ol>
        </p>
        <div className="flex sm:text-2xl text-lg justify-evenly">
          <a
            href="/"
            className="text-pink font-bold hover:cursor-pointer hover:underline"
          >
            Главная
          </a>
          <a
            href="/contacts"
            className="text-pink font-bold hover:cursor-pointer hover:underline"
          >
            Контакты
          </a>
          <a
            href="/products/0=Букеты"
            className="text-pink font-bold hover:cursor-pointer hover:underline"
          >
            Букеты
          </a>
        </div>
        <SocLinks className="flex flex-row gap-4 h-10 text-accent mx-auto my-10" />
      </div>
    </div>
  );
};

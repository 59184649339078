import React from "react";
import { Link } from "react-router-dom";
import Footer, { SocLinks } from "./Footer";
import Navbar from "./Navbar";
import mainImg from "../img/artiom-vallat-ZYSmVtcZGwg-unsplash.jpg";
import { XIcon } from "@heroicons/react/outline";

const InfoPage = () => {
  return (
    <div className="mainWrapper">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <div className="bodywrapper">
        <div
          className="rounded-lg w-full mb-10 px-10 py-44 sm:py-64 relative"
          style={{
            backgroundImage: `url(${mainImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
          }}
        >
          <h2 className="text-3xl sm:text-4xl font-bold uppercase text-pink/70 max-w-sm md:max-w-sm ml-auto pl-14 sm:pl-10 text-right">
            Условия доставки на{" "}
            <span className="font-[Taurus] text-accent whitespace-nowrap">
              8 марта
            </span>
          </h2>
        </div>
        <div className="max-w-xl flex flex-col gap-5 mx-auto text-center">
          <p className="sm:text-2xl text-lg">
            <b>Заказы</b> на 6, 7, 8 числа принимаются до <b>5 марта</b>!
          </p>
          <p className="sm:text-2xl text-lg">
            Вы можете выбрать{" "}
            <a
              href="/products/all"
              className="text-accent font-bold hover:cursor-pointer hover:underline"
            >
              готовый букет
            </a>{" "}
            на сайте или заказать индивидуальный букет,{" "}
            <a
              href="/contacts"
              className="text-pink font-bold hover:cursor-pointer hover:underline"
            >
              написав нам
            </a>{" "}
            в соц. сетях или мессенджерах
          </p>
          <p className="sm:text-2xl text-lg">
            <b>Доставка</b> на 7 и 8 марта будет осуществляться в 3 интервала:
            <ol className="mt-1 sm:text-xl">
              <li>09:00 - 13:00</li>
              <li>13:00 - 19:00</li>
              <li>19:00 - 21:00</li>
            </ol>
          </p>
          <div className="flex sm:text-2xl text-lg justify-evenly">
            <a
              href="/"
              className="text-pink font-bold hover:cursor-pointer hover:underline"
            >
              Главная
            </a>
            <a
              href="/contacts"
              className="text-pink font-bold hover:cursor-pointer hover:underline"
            >
              Контакты
            </a>
            <a
              href="/products/0=Букеты"
              className="text-pink font-bold hover:cursor-pointer hover:underline"
            >
              Букеты
            </a>
          </div>
          <SocLinks className="flex flex-row gap-4 h-10 text-accent mx-auto my-10" />
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
